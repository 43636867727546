import config from "../config";
import fetch from "../services/fetch";

export default {
  getActualites,
  getOne,
};

function getActualites(options) {
  const page = options.page || 0;
  const limit = options.limit || 15;

  return fetch(config.expertPlus.url + "content", {
    method: "get",
    params: {
      "api-key": config.expertPlus.key,
      "types[0]": "actualite",
      limit: limit,
      fields: "all",
      page: page,
    },
  });
}

function getOne(id) {
  return fetch(config.expertPlus.url + "content/" + id, {
    method: "get",
    params: {
      "api-key": config.expertPlus.key,
    },
  });
}
