<template>
  <md-card class="loading-card">
    <md-card-content class="loading-card__content">
      <md-progress-spinner
        class="loading-card__spinner"
        md-mode="indeterminate"
      />

      <p class="loading-card__message" v-html="text" />
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "LoadingCard",

  props: {
    text: {
      type: String,
      default: "Chargement en cours",
    },
  },
};
</script>

<style lang="scss">
.loading-card {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 40px;
    margin-top: 32px;
    .loading-card__spinner {
      margin: 0 auto;
      .md-progress-spinner-circle {
        stroke: var(--bg-primary);
      }
    }
  }

  &__message {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    font-family: var(--font-extrabold);
  }
}
</style>
