<template>
  <div class="news-article-page">
    <Banner>
      <div class="banner-content">
        <MeepIconNews class="app-icon-stroke-inverted" />
        <span class="banner-content__text">{{ $t("menu.news") }}</span>
      </div>
    </Banner>
    <div class="news-article-page-content page-layout">
      <PageHeader :has-back="true" :title="$t('menu.news')" @back="goBack" />

      <LoadingCard v-if="loader" />

      <div v-else class="news-article-page-detail">
        <img
          :src="getActuImageSrc(article)"
          alt="logo"
          class="news-article-page__image"
        />

        <md-card>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">
                {{ article.title }}
              </div>

              <div class="md-subtitle">
                Créé le {{ $$filters.formatDate(article.created) }}
              </div>
            </md-card-header-text>
          </md-card-header>

          <md-card-content v-if="article">
            <div class="md-layout md-alignment-center-center"></div>

            <p
              v-if="article.body"
              class="md-card-content-main"
              v-html="article.body[0].value"
            />

            <div
              v-for="(chapitre, index) of article.field_chapitres"
              :key="index"
            >
              <h2>{{ chapitre.chapitre_titre }}</h2>
              <div v-html="chapitre.chapitre_contenu"></div>
            </div>

            <div
              v-if="
                article &&
                  article.fields &&
                  article.fields.field_fichier_attache &&
                  article.fields.field_fichier_attache['und']
              "
            >
              <h3>Téléchargements</h3>
              <ul>
                <li
                  v-for="fichier of article.fields.field_fichier_attache['und']"
                  :key="fichier"
                >
                  <a href rel="nofollow" target="_blank">
                    {{ fichier.filename + " " + fichier.description }}
                  </a>
                </li>
              </ul>
            </div>

            <div v-if="article.field_ressource_liee">
              <h3>Ressources liées</h3>

              <div class="t-list ressource-list">
                <div
                  v-for="(ressource, index) of article.field_ressource_liee"
                  :key="index"
                  class="t-list-item ressource-item"
                  @click="$router.push('/dashboard/news/' + ressource.nid)"
                >
                  <div class="t-list-content">
                    <div class="ressource-title">
                      {{ ressource.titre }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import news from "../../model/news";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconNews from "@/components/icons/MeepIconNews.vue";

export default {
  name: "ViewNews",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconNews,
  },

  data() {
    return {
      article: {},
      loader: false,
    };
  },

  mounted() {
    this.getArticle(this.$route.params.id);
  },

  methods: {
    getArticle(id) {
      this.loader = true;
      news.getOne(id).then(article => {
        this.article = article;
        this.$nextTick(() => {
          this.loader = false;
        });
      });
    },

    getActuImageSrc(actu) {
      if (actu.fields && actu.fields.field_image.und) {
        return (
          "https://expertplus.expertsa.net/sites/default/files/" +
          actu.fields.field_image.und[0].filename
        );
      } else {
        return "assets/images/default-image.jpg";
      }
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app .news-article-page {
  .page-header {
    padding: 0 44px 0 25px;

    .md-theme-default {
      .md-icon.md-primary {
        width: var(--back-button-size-lg);
        height: var(--back-button-size-lg);
        border-radius: 50%;
        padding: 0;
        @include for-lg {
          width: var(--back-button-size-xl);
          height: var(--back-button-size-xl);
        }
      }
    }
  }

  .banner {
    .md-title {
      .banner-content {
        .app-icon-stroke-inverted {
          width: toRem(20);
          height: toRem(20);
          @include for-lg {
            width: toRem(30);
            height: toRem(30);
          }
        }
      }
    }
  }

  .page-layout {
    .md-card.md-theme-default {
      border-radius: 27px;
      padding: 10px 20px;
      margin: 0;
      height: fit-content;
      box-shadow: none;
      flex: 0 0 30%;
      @include for-lg {
        flex: 0 0 48%;
      }

      .md-title {
        font-family: var(--font-bold);
        font-size: toRem(25);
        margin-bottom: 5px;
        line-height: 1.21;
        @include for-lg {
          font-size: 40px;
        }
      }

      .md-subtitle {
        font-family: var(--font-bold);
        font-style: italic;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .md-card-content {
        font-family: var(--font);
        font-size: 15px;
      }

      .md-card-content-main {
        p {
          font-size: toRem(12);
          line-height: 1.33;
          font-family: var(--font);
          font-size: toRem(12);
          @include for-lg {
            font-size: 15px;
          }
        }
      }
    }
  }

  &-detail {
    display: flex;
    flex-direction: row;
    //max-width: 75%;
    margin-left: 20px;
    gap: toRem(50);
  }

  &__image {
    flex: 0 0 50%;
    border-radius: 27px;
    @include for-lg {
      flex: 0 0 26%;
    }
  }

  .loading-div {
    contain: content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;

    .circular {
      animation: rotate 2s linear infinite;
      height: 60px;
      width: 60px;
      position: relative;
    }

    .path {
      stroke: #d31027;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
  }
}
</style>
